@import url("https://use.typekit.net/nvm5tmt.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  h1,h2,h3,h4 {
    @apply text-xl font-acumin-pro mb-2;
  }
  p,a,figcaption,li {
    @apply font-source-code text-sm;
  }
  ul li {
    @apply list-none pt-1 md:pt-2;
  }
}
@layer components {
  .block-content, p {
    @apply py-1 md:py-2;
  }
}
